import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';

export interface BankDetail {
  accountNumber: string;
  ifscCode: string;
  bankName: string;
  bankCode: string;
  bankID: string;
  accountHolder: string;
}

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  private _isLoading = new BehaviorSubject<boolean>(false);
  public isLoading$ = this._isLoading.asObservable();
  constructor(
    private readonly http: HttpClient,
    private readonly messageService: MessageService
  ) {}

  saveBankDetails(payload: BankDetail) {
    return this.http.post(`${environment.digigoldbe}/sip/add-bank`, payload);
  }

  getBankList() {
    return this.http.get(`${environment.digigoldbe}/sip/bank-list`);
  }

  getBankDetails() {
    return this.http.get(`${environment.digigoldbe}/sip/bank-details`);
  }

  deleteBankDetails() {
    return this.http.delete(`${environment.digigoldbe}/sip/bank-details`);
  }

  showMessage(severity: 'success' | 'error' | 'warn' | 'info', msg: any) {
    this.messageService.add({
      severity: severity,
      summary: severity.charAt(0).toUpperCase() + severity.slice(1),
      detail: msg,
    });
  }

  show() {
    this._isLoading.next(true);
  }

  hide() {
    this._isLoading.next(false);
  }
}
